import {FC, useCallback, MouseEvent} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {size} from "lodash";
import {useStore} from "modules/hooks";
import {HamburgerButton} from "./HamburgerButton";
import {MenuItem} from "./MenuItem";
import {ReactComponent as MenuLogo} from "assets/images/menu-logo.svg";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	z-index: 11;
	height: 100vh;
	width: 0;
`;

const MenuBar = styled.div`
	color: ${({theme}) => theme.color.menu};
	background: ${({theme}) => theme.background.menu};
	height: 100%;
	width: 320px;
	left: -400px;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
	overflow: hidden;
	box-shadow: 0 0 5px rgb(0 0 0 / 20%);

	&.open {
		left: 0;
	}

	@media (min-width: 400px) {
		width: 400px;
	}

	@media (min-width: 600px) {
		width: 500px;
		left: -500px;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 600px;
		left: -600px;
	}
`;

const MenuBarInner = styled.div`
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 46px 0 20px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: 46px 0 140px;
	}
`;

const StepListScroll = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	overflow: auto;
`;

const StepList = styled.div`
	display: flex;
	flex-flow: column;
	height: 100%;
	margin-top: 20px;
	width: 100%;

	/* Hide scrollbar for Chrome, Safari and Opera */

	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-top: 40px;
	}
`;

export const Menu: FC = observer(() => {
	const {UIQuestionsProgress, I18n} = useStore();
	const {isMenuOpen, viewedQuestionID = 0, allItemsByID} = UIQuestionsProgress;
	const openClassName = isMenuOpen ? "open" : "";
	const viewedQuestion = allItemsByID[viewedQuestionID];

	const closeMenu = useCallback(
		({currentTarget, relatedTarget}: MouseEvent<HTMLDivElement>) => {
			if (relatedTarget === window) {
				return;
			}
			if (currentTarget.contains(relatedTarget as Node)) {
				return;
			}

			UIQuestionsProgress.toggleMenu(false);
		},
		[UIQuestionsProgress]
	);

	const toggleMenu = useCallback(() => {
		UIQuestionsProgress.toggleMenu();
	}, [UIQuestionsProgress]);

	const items = UIQuestionsProgress.allItems;
	const isQuizComplete = UIQuestionsProgress.isAllQuestionsAnswered;
	const finalScreenIndex = size(items);

	const finalScreenData = {
		id: UIQuestionsProgress.resultScreenID,
		shortTitle: I18n.t("menu.items.last"),
		hasAnswer: isQuizComplete,
		isActive: isQuizComplete,
		index: finalScreenIndex,
	};

	return (
		<Wrapper onMouseLeave={closeMenu}>
			<HamburgerButton
				className={openClassName}
				onClick={toggleMenu}
				color={viewedQuestion?.colors.burger}
			/>

			<MenuBar className={openClassName}>
				<MenuBarInner>
					<StepListScroll>
						<MenuLogo />

						<StepList>
							{items.map((question, index) => (
								<MenuItem key={question.id} index={index} {...question} />
							))}
							<MenuItem showTitle {...finalScreenData} />
						</StepList>
					</StepListScroll>
				</MenuBarInner>
			</MenuBar>
		</Wrapper>
	);
});
