import {FC, Fragment} from "react";
import Home from "./Home";
import {NotFoundModal} from "components";

const NotFound: FC = () => {
	return (
		<Fragment>
			<NotFoundModal />
			<Home isShowLandingModal={false} />
		</Fragment>
	);
};

export default NotFound;
