import {FC, Fragment, useEffect} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {CocktailsList, Exist, Footer, LandingModal, Menu, Questions, ScrollCTA} from "components";

const Container = styled.div`
	position: relative;
`;

interface IHome {
	isShowLandingModal?: boolean;
}

const Home: FC<IHome> = observer(({isShowLandingModal = true}) => {
	const {UIQuestionsProgress, modal} = useStore();
	const {isAllQuestionsAnswered} = UIQuestionsProgress;

	useEffect(() => {
		if (isShowLandingModal) {
			modal.setIsShowLandingModal(true);
		}
	}, [modal, isShowLandingModal]);

	return (
		<Fragment>
			<LandingModal />
			<Container>
				<Menu />
				<CocktailsList />
				<Questions />
				<ScrollCTA />
			</Container>
			<Exist when={isAllQuestionsAnswered}>
				<Footer />
			</Exist>
		</Fragment>
	);
});

export default Home;
