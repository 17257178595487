import {FC, useCallback} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow.svg";

const Wrapper = styled.div`
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 54px;
	z-index: 3;
	background: #ffffff;
	width: 44px;
	height: 44px;
	border-radius: 100%;
	left: 50%;
	transform: translateX(-50%);

	&.isVisible {
		display: flex;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		display: none;

		&.isVisible {
			display: none;
		}
	}
`;

const getClassName = ({isScrollCTAVisible}: Record<string, boolean>) => {
	let className = "";

	if (isScrollCTAVisible) {
		className += " isVisible";
	}

	return className;
};

export const ScrollCTA: FC = observer(() => {
	const {UIQuestionsProgress} = useStore();

	const className = getClassName({
		isScrollCTAVisible: UIQuestionsProgress.isScrollCTAVisible,
	});

	const clickHandler = useCallback(() => {
		const element = document.getElementById("root");

		if (element) {
			element.scrollTo({
				top: element.scrollHeight,
				left: 0,
				behavior: "smooth",
			});
		}
	}, []);

	return (
		<Wrapper className={className} onClick={clickHandler}>
			<ArrowIcon />
		</Wrapper>
	);
});
