import {action, computed, makeObservable, observable, values} from "mobx";
import {RootStore} from "modules/stores/RootStore";
import {filter, findIndex, isEmpty, isUndefined, keyBy, size} from "lodash";
import {Api, IAnswersPayload} from "modules/utils/Api";
import {RequestState} from "modules/enums";

export class UIQuestionsProgress<TModel extends RootStore> {
	resultScreenID = Number.MAX_SAFE_INTEGER;

	@observable isMenuOpen: boolean = false;
	@observable answers: Record<string, number> = {};
	@observable viewedQuestionID?: number;
	@observable activeQuestionIndex: number = 0;
	@observable apiState: RequestState = RequestState.IDLE;

	constructor(private readonly rootStore: Pick<TModel, "questions" | "fingerprint" | "modal">) {
		makeObservable(this);
	}

	@action toggleMenu(isOpen?: boolean) {
		if (isOpen !== undefined) {
			this.isMenuOpen = isOpen;
		} else {
			this.isMenuOpen = !this.isMenuOpen;
		}
	}

	@computed get allItems() {
		const questions = this.rootStore.questions.entities;

		const activeIndex = this.activeQuestionIndex;

		return questions.map((question, index) => {
			const hasAnswer = !isUndefined(this.answers[question.id]);
			const isActive = activeIndex === index;
			const questionNumber = index + 1;

			return {hasAnswer, isActive, questionNumber, ...question};
		});
	}

	@computed get isEmpty() {
		return isEmpty(this.allItems);
	}

	@computed get allItemsByID() {
		return keyBy(this.allItems, "id");
	}

	@computed get progressItems() {
		const maxIndex = size(filter(this.allItems, {hasAnswer: true}));
		return this.allItems.filter((_, index) => index <= maxIndex);
	}

	@computed get isAllQuestionsAnswered() {
		return !isEmpty(this.allItems) && this.allItems.every(({hasAnswer}) => hasAnswer);
	}

	@computed get isAnyQuestionAnswered() {
		return !isEmpty(this.allItems) && this.allItems.some(({hasAnswer}) => hasAnswer);
	}

	@computed get isScrollCTAVisible() {
		return Boolean(this.viewedQuestionID && this.answers[this.viewedQuestionID]);
	}

	@computed
	private get nextQuestionID() {
		const nextQuestionIndex = findIndex(this.allItems, {id: this.viewedQuestionID}) + 1;
		if (nextQuestionIndex > 0 && this.allItems[nextQuestionIndex]?.id) {
			return this.allItems[nextQuestionIndex]?.id;
		}

		return this.resultScreenID;
	}

	scrollToNextScreen(id?: number) {
		document.getElementById(`question-${id || this.nextQuestionID}`)?.scrollIntoView({
			block: "start",
			behavior: "smooth",
		});
	}

	@action selectAnswer(questionID: number, optionID: number) {
		const currentQuestionIndex = findIndex(this.allItems, {id: questionID});
		const nextQuestionIndex = currentQuestionIndex + 1;

		if (!this.activeQuestionIndex || this.activeQuestionIndex < nextQuestionIndex) {
			this.activeQuestionIndex = nextQuestionIndex;
		}

		if (this.answers[questionID] !== optionID) {
			this.answers[questionID] = optionID;
			setTimeout(() => this.scrollToNextScreen(), 500);
		}
	}

	async saveAnswers() {
		if (this.apiState === RequestState.Requested) {
			return;
		}

		const payload: IAnswersPayload = {
			user_key: this.rootStore.fingerprint.visitorId,
			answers: values(this.answers) as number[],
		};

		this.apiState = RequestState.Requested;

		try {
			await Api.Answers.save(payload);
		} catch (err) {
			this.rootStore.modal.setError((err as Error).message);
		} finally {
			this.apiState = RequestState.Received;
		}
	}

	@action setViewedQuestion(questionID: number) {
		this.viewedQuestionID = questionID;
	}

	@action resetProgress() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		this.answers = {};
		this.apiState = RequestState.IDLE;
	}
}
