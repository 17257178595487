export interface ICocktail {
	id: number;
	fullName: string;
	shortName: string;
	priority: string;
	mainVideo: string;
	mainImage: string;
	mainImgMobile: string;
	mainImageRankings: string;
	teamLogo: string;
	teamLogoRankings: string;
	backgroundColor: string;
	confettiColors: string[];
	recipe: string[];
}

export class Cocktail implements ICocktail {
	id: number = 0;
	fullName: string = "";
	shortName: string = "";
	priority: string = "";
	mainVideo: string = "";
	mainImage: string = "";
	mainImgMobile: string = "";
	mainImageRankings: string = "";
	teamLogo: string = "";
	teamLogoRankings: string = "";
	backgroundColor: string = "";
	confettiColors: string[] = [];
	recipe: string[] = [];

	constructor(args: Cocktail) {
		Object.assign(this, args);
	}
}
