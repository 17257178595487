import {ChangeEvent, FC, Fragment, useCallback, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {MenuItem} from "@mui/material";
import {useStore} from "modules/hooks";
import {Exist, LogoWrapper, PrimaryButton, Select} from "components";
import {Modal, Overlay} from "components/Modal";
import {ReactComponent as Logo} from "assets/images/modal-logo.svg";
import {DateTime, Info, Interval} from "luxon";
import {every} from "lodash";
import {createConnextraScriptTag} from "modules/utils";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
	align-items: center;
	padding: 24px 16px;
	max-width: 360px;
	font-weight: 500;
`;

const Title = styled.div`
	font-size: 14px;
	line-height: 130%;
	letter-spacing: 0.84px;
	text-align: center;
	font-weight: 700;
`;

const Desc = styled.div`
	font-size: 12px;
	line-height: 160%;
	padding: 0 16px;
	text-align: center;
`;

const Inputs = styled.div`
	display: grid;
	grid-template-columns: minmax(130px, 1fr) 1fr minmax(107px, 1fr);
	grid-column-gap: 6px;

	&.error {
		.MuiInputLabel-root {
			color: #eb5c0e;
		}

		.MuiOutlinedInput-root fieldset {
			border-color: #eb5c0e;
		}
	}

	&.valid {
		.MuiInputLabel-root {
			color: ${({theme}) => theme.color.primary};
		}

		.MuiOutlinedInput-root fieldset {
			border-color: #69f1fa;
		}
	}
`;

const Error = styled.div`
	color: #eb5c0e;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 19.2px */
	flex: 1 1 160px;
	grid-column: 1 / 4;
`;

const Button = styled(PrimaryButton)`
	font-size: 14px;
`;

const MIN_USER_YEAR = 21;

const months = Info.months();

const days = Array.from({length: 31}).map((_, i) => i + 1);

const years = Interval.fromDateTimes(
	DateTime.local(1900).endOf("year"),
	DateTime.now().startOf("year")
)
	.splitBy({year: 1})
	.map((d) => (d.start as DateTime).year)
	.reverse();

interface IState {
	month: string | number;
	day: string | number;
	year: string | number;
}

const checkError = (state: IState, date: DateTime) => {
	const isFilled = every(state, (field) => field !== "");

	if (!isFilled) {
		return {error: "", className: "", isDisabled: true};
	}

	if (!date.isValid) {
		return {error: "modal.landing.invalid_date", className: "error", isDisabled: true};
	}
	const isYoung = date.diffNow("years").years > -MIN_USER_YEAR;

	if (isYoung) {
		return {error: "modal.landing.invalid_21", className: "error", isDisabled: true};
	}

	return {error: "", className: "valid", isDisabled: false};
};

export const LandingModal: FC = observer(() => {
	const {modal, I18n} = useStore();
	const [state, setState] = useState({
		month: "",
		day: "",
		year: "",
	});
	const date = DateTime.local(
		Number(state.year),
		Number(state.month),
		Number(state.day),
		0,
		0,
		0,
		0
	);
	const {error, className, isDisabled} = checkError(state, date);
	const isError = Boolean(error);

	const handleClose = useCallback(() => {
		modal.setIsShowLandingModal(false);
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/cc78ad760aa2/f2p_personalityquiz_startquiz`
		);
	}, [modal]);

	const onChangeFilter = useCallback(
		(name: keyof typeof state) => (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;

			setState((prev) => ({
				...prev,
				[name]: value,
			}));
		},
		[setState]
	);

	if (!modal.isShowLandingModal) {
		return null;
	}

	return (
		<Fragment>
			<Overlay />
			<Modal>
				<Container>
					<LogoWrapper>
						<Logo />
					</LogoWrapper>

					<Content>
						<Title>{I18n.t("modal.landing.title")}</Title>
						<Desc>
							{I18n.t("modal.landing.desc_1")}
							<br />
							{I18n.t("modal.landing.desc_2")}
						</Desc>

						<Inputs className={className}>
							<Select
								label={I18n.t("modal.landing.month")}
								value={state["month"]}
								onChange={onChangeFilter("month")}>
								{months.map((month, index) => (
									<MenuItem key={month} value={index + 1}>
										{month}
									</MenuItem>
								))}
							</Select>

							<Select
								label={I18n.t("modal.landing.day")}
								value={state["day"]}
								onChange={onChangeFilter("day")}>
								{days.map((day, index) => (
									<MenuItem key={day} value={index + 1}>
										{day}
									</MenuItem>
								))}
							</Select>

							<Select
								label={I18n.t("modal.landing.year")}
								value={state["year"]}
								onChange={onChangeFilter("year")}>
								{years.map((year) => (
									<MenuItem key={year} value={year}>
										{year}
									</MenuItem>
								))}
							</Select>

							<Exist when={isError}>
								<Error>{I18n.t(error)}</Error>
							</Exist>
						</Inputs>

						<Button onClick={handleClose} disabled={isDisabled}>
							{I18n.t("modal.landing.button")}
						</Button>
					</Content>
				</Container>
			</Modal>
		</Fragment>
	);
});
