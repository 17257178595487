import {makeObservable, observable} from "mobx";

export class Modal {
	@observable public error: string = "";
	@observable public isShowLandingModal: boolean = false;

	constructor() {
		makeObservable(this);
	}

	setError(err: string) {
		this.error = err;
	}

	clearError() {
		this.error = "";
	}

	setIsShowLandingModal(isShow: boolean) {
		this.isShowLandingModal = isShow;
	}
}
