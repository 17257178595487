import {FC, Fragment} from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {QuestionType} from "modules/enums";
import {Exist, ResultScreen} from "components";
import {QuestionGeneral} from "./QuestionGeneral";
import {TrackQuestionViewingState} from "./common";

const {General} = QuestionType;

const questionMap = new Map([[General, QuestionGeneral]]);

export const Questions: FC = observer(() => {
	const {UIQuestionsProgress} = useStore();
	const {isAllQuestionsAnswered, resultScreenID} = UIQuestionsProgress;

	const questionsList = UIQuestionsProgress.progressItems.map((question) => {
		const {type, id} = question;
		const Component = questionMap.get(type);

		return Component ? (
			<TrackQuestionViewingState key={id} id={question.id}>
				<Component {...question} />
			</TrackQuestionViewingState>
		) : null;
	});

	return (
		<Fragment>
			{questionsList}

			<Exist when={isAllQuestionsAnswered}>
				<TrackQuestionViewingState id={resultScreenID}>
					<ResultScreen />
				</TrackQuestionViewingState>
			</Exist>
		</Fragment>
	);
});
