import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {IS_SECRET, LOGIN_DISABLED} from "modules/constants/secretPage";
import {PersistStorage} from "modules/utils";
import {useStore} from "modules/hooks";
import Secret from "pages/Secret";

export const Layout: FC = () => {
	const {I18n, cocktails, questions} = useStore();
	const [count, setCount] = useState(0);
	const isSecret = count < 10;

	useEffect(() => {
		if (!isSecret) {
			PersistStorage.SET(LOGIN_DISABLED, "false");
		}
	}, [isSecret]);

	useEffect(() => {
		void I18n.request();
		void cocktails.request();
		void questions.request();
	}, [I18n, cocktails, questions]);

	const clickHandler = useCallback(() => {
		setCount((count) => ++count);
	}, [setCount]);

	if (IS_SECRET && isSecret) {
		return <Secret clickHandler={clickHandler} />;
	}

	return (
		<Fragment>
			<Outlet />
		</Fragment>
	);
};
