import styled from "styled-components";

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
`;

export const Modal = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 20px);
	max-height: calc(100% - 20px);
	overflow: auto;
	max-width: 600px;
	min-height: 200px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
`;

export const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 20px;
	background: ${({theme}) => theme.background.primary};
	width: 100%;
`;
