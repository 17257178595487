import {makeObservable, observable} from "mobx";
import {Api} from "modules/utils/Api";
import {ApiModel} from "modules/stores/Base/ApiModel";
import {get} from "lodash";
import {APP_ENVIRONMENT} from "modules/constants";
import {AxiosResponse} from "axios";

type TResponse = Record<string, string>;

export class I18n extends ApiModel<TResponse> {
	@observable public data: Record<string, string> = {};

	constructor() {
		super(Api.JSON.lang);
		makeObservable(this);
	}

	protected performResponse(response: AxiosResponse<TResponse>) {
		this.data = response.data;
	}

	t(path: string, args?: Record<string, string>) {
		return this.translate(path, args);
	}

	translate(path: string, args?: Record<string, string>) {
		const defaultValue = APP_ENVIRONMENT === "uat" ? path : "";
		const translate = get(this.data, path, defaultValue);

		if (args) {
			return translate.replace(/{{(\w+)}}/g, (_, replaceKey: string) =>
				get(args, replaceKey, "")
			);
		}

		return translate;
	}
}
