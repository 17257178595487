import styled from "styled-components";
import {TextField} from "@mui/material";

export const Input = styled(TextField)`
	width: 100%;

	&& label {
		font-size: 16px;
		color: ${({theme}) => theme.color.tertiary};

		&[data-shrink="true"] {
			top: 4px;
			font-size: 12px;
		}
	}

	& .MuiFilledInput-root:after,
	& .MuiFilledInput-root:before {
		border-bottom-color: rgba(0, 0, 0, 0.42);
	}

	& fieldset {
		border-color: rgba(0, 0, 0, 0.42);
	}

	&:hover fieldset {
		border-color: rgba(0, 0, 0, 0.42);
	}

	&.Mui-focused fieldset {
		border-color: rgba(0, 0, 0, 0.42);
	}
`;
