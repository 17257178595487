import {FC, useRef, useState} from "react";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";
import {IQuestion} from "modules/stores/Models";
import {
	LogoContainer,
	QuestionContainer,
	QuestionCount,
	QuestionTitle,
	QuestionWrapper,
} from "./common";
import {Exist, SmirnoffAds} from "components";
import {useSlider, useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {IconArrow} from "components/Arrow";
import {ReactComponent as Logo} from "assets/images/question-logo.svg";
import bg from "assets/images/bg.png";

interface IWrapperProps {
	background: null | string;
}

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${({background}) => background || "#ffffff"};
	position: relative;

	&:after {
		position: absolute;
		background: url("${bg}") no-repeat center center / contain;
		top: 0;
		left: 8%;
		width: 100%;
		height: 100%;

		${({theme}) => theme.breakpoints.up("mobile")} {
			content: "";
		}
	}
`;

const OptionsList = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px;
	position: relative;
	width: 100%;
	max-width: 332px;
	overflow: hidden;
	scroll-snap-type: x mandatory;
	justify-content: center;

	${({theme}) => theme.breakpoints.up("mobile")} {
		max-width: fit-content;
		display: flex;
		flex-wrap: nowrap;
		gap: 0;
		justify-content: flex-start;
	}
`;

const ImageContainer = styled.div`
	position: relative;
	padding-top: 88%;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding-top: 100%;
	}
`;

const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const FigureCaption = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 160%;
	padding: 3px 10px;
	min-height: 45px;
	flex-grow: 1;
	font-weight: 700;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 16px;
		padding: 5px 10px;
		min-height: 62px;
	}
`;

const Option = styled.div<{color: string; background: string}>`
	display: flex;
	flex-direction: column;
	text-align: center;
	cursor: pointer;
	transition: all 0.5s;
	color: ${({color}) => color || "#0000000"};
	background: ${({background}) => background || "#ffffff"};
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	margin-bottom: 4px;
	min-width: 120px;
	max-width: 160px;
	width: 100%;

	${({theme}) => theme.breakpoints.up("mobile")} {
		max-width: 230px;
		min-width: 260px;
		margin-right: 20px;
		width: 100%;
	}

	${({theme}) => theme.breakpoints.up("desktop")} {
		max-width: 300px;
		min-width: 300px;
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		${FigureCaption} {
			background-size: 100% 1px;
		}
	}

	&.fade {
		opacity: 0.5;
	}
`;

const WrapperScrollNaw = styled.div`
	display: flex;
	margin-top: 10px;
	align-self: flex-end;
`;

const ScrollButton = styled.button`
	background: #ffffff;
	border: none;
	width: 40px;
	height: 40px;
	cursor: pointer;

	&:disabled {
		background: #a3a3a3;
	}

	${IconArrow} {
		font-size: 11px;
		margin-left: 10px;

		&:before,
		&:after {
			background: #000000;
		}
	}
`;

const ScrollRight = styled(ScrollButton)`
	margin-left: 10px;

	${IconArrow} {
		transform: rotate(-88deg);
	}
`;

const ScrollLeft = styled(ScrollButton)`
	${IconArrow} {
		margin-left: -10px;
		transform: rotate(88deg);
	}
`;

const getStateClass = (currentID: number, selectedID: number | null) => {
	if (selectedID === null) {
		return "";
	}

	if (currentID === selectedID) {
		return "active";
	}

	return "fade";
};

export const QuestionGeneral: FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	questionNumber,
	colors,
}) => {
	const {UIQuestionsProgress} = useStore();
	const [selectedID, setSelectedID] = useState<null | number>(null);
	const element = useRef<HTMLDivElement>(null);
	const isMobile = useMediaQuery("(max-width: 768px)");
	const slider = useSlider(element);
	const isSliderVisible = Boolean(!isMobile && slider && slider.is_show_nav);

	return (
		<Wrapper background={colors.background}>
			<QuestionContainer>
				<LogoContainer
					smirnoffLogoBackground={colors.smirnoffLogoBackground}
					gameDaysText={colors.gameDaysText}
					smirnoffLogoText={colors.smirnoffLogoText}
					weDoText={colors.weDoText}
					sponsorText={colors.sponsorText}>
					<Logo />
				</LogoContainer>

				<QuestionCount color={colors.questionNo}>Q{questionNumber}</QuestionCount>
				<QuestionTitle color={colors.question}>{title}</QuestionTitle>

				<OptionsList ref={element}>
					{options.map(({id, title, image, mobileImage}) => (
						<Option
							key={id}
							className={getStateClass(id, selectedID)}
							background={colors.optionBackground}
							color={colors.optionText}
							onClick={() => {
								UIQuestionsProgress.selectAnswer(questionID, id);
								setSelectedID(id);
							}}>
							<ImageContainer>
								<Image src={`${IMAGES_URL}${isMobile ? mobileImage : image}`} />
							</ImageContainer>
							<FigureCaption>{title}</FigureCaption>
						</Option>
					))}
				</OptionsList>
				<Exist when={isSliderVisible}>
					<WrapperScrollNaw>
						<ScrollLeft
							onClick={slider?.scrollLeft}
							disabled={slider?.is_disabled_left}>
							<IconArrow />
						</ScrollLeft>
						<ScrollRight
							onClick={slider?.scrollRight}
							disabled={slider?.is_disabled_right}>
							<IconArrow />
						</ScrollRight>
					</WrapperScrollNaw>
				</Exist>
			</QuestionContainer>

			<SmirnoffAds />
		</Wrapper>
	);
};
