import React, {useEffect} from "react";
import {Footer, LogoContainer} from "components";
import styled from "styled-components";
import {useStore} from "modules/hooks";
import {ReactComponent as Logo} from "assets/images/question-logo.svg";
import {observer} from "mobx-react";

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	flex-grow: 1;
	background: #d90f1b;
	padding-top: 103px;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 20px 20px 50px;
	flex-grow: 1;
	max-width: 90%;

	${({theme}) => theme.breakpoints.up("mobile")} {
		max-width: 85%;
	}

	${({theme}) => theme.breakpoints.up("tablet")} {
		max-width: 70%;
	}
`;

const Title = styled.h1`
	color: ${({theme}) => theme.color.secondary};
	text-align: center;
	font-weight: 700;
	line-height: 130.5%;
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 1.08px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		letter-spacing: 2.04px;
		font-size: 24px;
	}

	${({theme}) => theme.breakpoints.up("tablet")} {
		font-size: 34px;
	}
`;

const SLogoContainer = styled(LogoContainer)`
	${({theme}) => theme.breakpoints.down("mobile")} {
		svg {
			height: 100px;
			width: auto;
		}
	}
`;

const OffSeason: React.FC = observer(() => {
	const {I18n} = useStore();

	useEffect(() => {
		void I18n.request();
	}, [I18n]);

	return (
		<Wrapper>
			<Container>
				<SLogoContainer>
					<Logo />
				</SLogoContainer>

				<TitleWrapper>
					<Title>{I18n.t("page.off_season.title")}</Title>
				</TitleWrapper>
			</Container>

			<Footer />
		</Wrapper>
	);
});

export default OffSeason;
