import {HttpClientService as HTTPClient} from "./HTTPClient";
import {API_URL, JSON_URL} from "modules/constants";

const APIClient = new HTTPClient({
	baseURL: API_URL,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

export interface IAnswersPayload {
	user_key: string;
	answers: number[];
}

export const Api = {
	JSON: {
		cocktails: <T>() => JSONClient.get<T>(`quiz/cocktails.json`, {t: Date.now()}),
		questions: <T>() => JSONClient.get<T>(`quiz/questions.json`),
		lang: <T>() => JSONClient.get<T>(`quiz/loco/en.json`),
	},
	Country: {
		get: <T>() => APIClient.get<T>("country"),
	},
	Answers: {
		save: <T>(params: IAnswersPayload) => APIClient.post<T>("en/quiz/answers/save", params),
	},
};

export * from "./ApiError";
