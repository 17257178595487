import {FC, Fragment, useCallback} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useStore} from "modules/hooks";
import {LogoWrapper, PrimaryButton} from "components";
import {Modal, Overlay} from "components/Modal";
import {ReactComponent as Logo} from "assets/images/modal-logo.svg";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
	align-items: center;
	padding: 24px 16px;
	max-width: 360px;
`;

const Title = styled.div`
	font-size: 14px;
	line-height: 130%;
	letter-spacing: 0.84px;
	text-align: center;
	color: #d90f1b;
	text-transform: uppercase;
`;

const Desc = styled.div`
	font-size: 12px;
	line-height: 160%;
	padding: 0 16px;
	text-align: center;
`;

const Button = styled(PrimaryButton)`
	font-size: 14px;
`;

export const ErrorModal: FC = observer(() => {
	const {modal, I18n} = useStore();
	const errorMessage = modal.error;

	const handleClose = useCallback(() => {
		modal.clearError();
	}, [modal]);

	if (!errorMessage) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal>
				<Container>
					<LogoWrapper>
						<Logo />
					</LogoWrapper>

					<Content>
						<Title>{I18n.t("modal.error.title")}</Title>
						<Desc>{I18n.t(errorMessage)}</Desc>

						<Button onClick={handleClose}>{I18n.t("modal.error.button")}</Button>
					</Content>
				</Container>
			</Modal>
		</Fragment>
	);
});
