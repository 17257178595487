import {FC} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {PureButton} from "components/Buttons";
import IconTW from "assets/images/icons/small-tw.svg";
import IconFB from "assets/images/icons/small-fb.svg";

const ShareButton = styled(PureButton)`
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 24px;
	height: 24px;
	color: ${({theme}) => theme.color.primary};
	transition: opacity, 0.25s ease-in-out;
	display: inline-block;
	vertical-align: middle;

	&:hover {
		opacity: 0.4;
	}
`;

export const ShareFacebook = styled(ShareButton)`
	background-image: url(${IconFB});
	margin-right: 10px;
`;

export const ShareTwitter = styled(ShareButton)`
	background-image: url(${IconTW});
`;

const ShareText = styled.div`
	margin-top: 8px;
	font-weight: 700;
	font-size: 16px;
	line-height: 34px;
`;

export const SocialShareLinks: FC = observer(() => {
	const {I18n} = useStore();
	const fbLink = "";
	const twLink = "";

	return (
		<div>
			<div>
				<ShareFacebook as="a" target="_blank" rel="nofollow noreferrer" href={fbLink} />
				<ShareTwitter as="a" target="_blank" rel="nofollow noreferrer" href={twLink} />
			</div>
			<ShareText>{I18n.t("share.general.message")}</ShareText>
		</div>
	);
});
