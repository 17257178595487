import {FC, Fragment, useCallback, useEffect} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import Confetti from "react-confetti";
import {partial} from "lodash";
import {useIsMobile, useIsMobileShare, useStore, useWindowSize} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {Exist, QuestionWrapper, SecondaryButton, SmirnoffAds} from "components";
import {createConnextraScriptTag, share} from "modules/utils";
import {ShareType} from "modules/enums";
import {ReactComponent as Logo} from "assets/images/result-page-logo.svg";
import {ReactComponent as ShareIcon} from "assets/images/icons/share.svg";
import {ReactComponent as RetryIcon} from "assets/images/icons/retry.svg";
import footer_logo from "assets/images/footer_logo.png";

const Wrapper = styled(QuestionWrapper)<{background?: string}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background: ${({background}) => background || "#000000"};
	min-height: 100vh;
	padding: calc(2vh + 40px) 0 calc(4vh + 60px);

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: calc(8vh + 130px) 3% calc(4vh + 60px);
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;

	${({theme}) => theme.breakpoints.up("mobile")} {
		flex-direction: row;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	img {
		width: 100%;
		max-width: 629px;
	}
`;

const LogoWrapper = styled.div`
	margin-bottom: 16px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-bottom: 48px;
	}
`;

const Title = styled.div`
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	margin-bottom: 4px;
	color: ${({theme}) => theme.color.secondary};

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 20px;
		text-align: left;
	}
`;

const CocktailName = styled(Title)`
	font-size: 20px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 24px;
	}
`;

const TeamImages = styled.div`
	display: flex;
	margin-top: 8px;

	img {
		max-height: 100px;
		height: 100px;
		width: auto;
	}
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	width: 100%;
	margin-top: 20px;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		justify-self: center;
		width: 100%;
		max-width: 335px;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		grid-template-columns: 1fr 1fr;
		flex-direction: row;

		button {
			max-width: none;

			&:nth-child(3) {
				grid-column: 1 / 3;
			}
		}
	}
`;

const Recipe = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 20px;
	margin: 20px auto;
	max-width: 850px;
	flex-direction: column;

	${({theme}) => theme.breakpoints.up("mobile")} {
		flex-direction: row;
	}
`;

const Ingredient = styled.div`
	background: #f2f2f2;
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	border-radius: 6px;
	padding: 0 8px;
	height: 24px;
	display: flex;
	color: #d90f1b;
	align-items: center;
	justify-content: center;
`;

const FooterBanner = styled.a`
	display: flex;
	width: 100%;
	background: #69f1fa;
	align-items: center;
	justify-content: space-between;
	padding: 12px 20px;
	height: 65px;
	margin-top: 8px;
	max-width: 320px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		height: 93px;
		max-width: 728px;
	}

	> div {
		display: flex;
		flex-direction: column;
		gap: 6px;

		${({theme}) => theme.breakpoints.up("mobile")} {
			gap: 12px;
		}
	}

	.title {
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 130.5%; /* 13.05px */
		letter-spacing: 0.6px;
		text-transform: uppercase;

		${({theme}) => theme.breakpoints.up("mobile")} {
			font-size: 16px;
		}
	}

	.logo {
		max-height: 100%;
		width: 60px;

		${({theme}) => theme.breakpoints.up("mobile")} {
			width: auto;
		}
	}

	.link {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 20px;

		${({theme}) => theme.breakpoints.up("mobile")} {
			height: 40px;
		}
	}
`;

export const ResultScreen: FC = observer(() => {
	const {height, width} = useWindowSize();
	const {UICocktailsList, I18n, UIQuestionsProgress} = useStore();
	const topCocktail = UICocktailsList.topCocktail;
	const {isMobile} = useIsMobile();
	const src = `${IMAGES_URL}${isMobile ? topCocktail?.mainImgMobile : topCocktail?.mainImage}`;
	const {isMobileShare} = useIsMobileShare();

	useEffect(() => {
		if (topCocktail) {
			void UIQuestionsProgress.saveAnswers();
		}
	}, [topCocktail, UIQuestionsProgress]);

	const shareHandler = useCallback(
		(type: ShareType) => {
			if (topCocktail) {
				share({sc: type, cocktailId: topCocktail.id});

				createConnextraScriptTag(
					`https://us.connextra.com/dcs/tagController/tag/cc78ad760aa2/f2p_personalityquiz_sharebutton`
				);
			}
		},
		[topCocktail]
	);

	const restartQuizHandler = useCallback(() => {
		UIQuestionsProgress.resetProgress();
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/cc78ad760aa2/f2p_personalityquiz_tryagain`
		);
	}, [UIQuestionsProgress]);

	const trackBannerAnalytics = useCallback(() => {
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/cc78ad760aa2/f2p_personalityquiz_clickherebutton`
		);
	}, []);

	if (!topCocktail) {
		return null;
	}

	return (
		<Fragment>
			<Wrapper background={topCocktail.backgroundColor}>
				<Container>
					<Content>
						<LogoWrapper>
							<Logo />
						</LogoWrapper>

						<Title>{I18n.t("result.title")}</Title>

						<CocktailName>{topCocktail.fullName}</CocktailName>

						<Recipe>
							{topCocktail.recipe.map((ingredient, index) => (
								<Ingredient key={index}>{ingredient}</Ingredient>
							))}
						</Recipe>

						<img src={src} alt={topCocktail.fullName} />

						<TeamImages>
							{topCocktail.teamLogo && (
								<img src={`${IMAGES_URL}${topCocktail.teamLogo}`} alt="" />
							)}
						</TeamImages>

						<FooterBanner>
							<div>
								<span className="title">
									{I18n.t("result.result_banner.title")}
								</span>
								<SecondaryButton
									className="link"
									as={"a"}
									href={
										"https://www.spiritspromos.com/smirnoff/regional-sweeps/en/"
									}
									rel="noreferrer noopener"
									target="_blank"
									onClick={trackBannerAnalytics}>
									{I18n.t("result.result_banner.cta")}
								</SecondaryButton>
							</div>
							<img className="logo" src={footer_logo} alt="" role="presentation" />
						</FooterBanner>

						<Buttons>
							<Exist when={isMobileShare}>
								<SecondaryButton onClick={partial(shareHandler, ShareType.MOBILE)}>
									{I18n.t("result.button.share")}
									<ShareIcon />
								</SecondaryButton>
							</Exist>

							<Exist when={!isMobileShare}>
								{/*<SecondaryButton onClick={partial(shareHandler, ShareType.TW)}>
									{I18n.t("result.button.tw")}
								</SecondaryButton>*/}

								<SecondaryButton onClick={partial(shareHandler, ShareType.FB)}>
									{I18n.t("result.button.fb")}
								</SecondaryButton>
							</Exist>

							<SecondaryButton onClick={restartQuizHandler}>
								{I18n.t("result.button.try_again")}
								<RetryIcon />
							</SecondaryButton>
						</Buttons>
					</Content>

					<Confetti
						width={width}
						height={height}
						colors={topCocktail.confettiColors}
						numberOfPieces={400}
						opacity={0.9}
					/>
				</Container>
			</Wrapper>

			<SmirnoffAds />
		</Fragment>
	);
});
