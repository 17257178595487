import styled from "styled-components";

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	padding: 20px 20px 26px;
	background: ${({theme}) => theme.background.form};
	gap: 44px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
`;

export const FormTitle = styled.div`
	font-weight: 700;
	font-size: 26px;
	line-height: 34px;
	text-align: center;
	color: ${({theme}) => theme.color.tertiary};
	text-transform: uppercase;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 32px;
	}
`;
