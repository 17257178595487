import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {buttonStyles} from "components";
import {BaseLinkStyles, BaseExternalLink} from "./BaseLink";

export const SecondaryLink = styled(NavLink)`
	${BaseLinkStyles};
	${({theme}) => buttonStyles(theme.button.secondary)}
`;

export const SecondaryExternalLink = styled(BaseExternalLink)`
	${BaseLinkStyles};
	${({theme}) => buttonStyles(theme.button.secondary)}
`;
