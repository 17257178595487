import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {RequestState} from "modules/enums";
import {size} from "lodash";
import {AxiosResponse} from "axios";

export class JSONCollection<TModel> {
	@observable apiState: RequestState = RequestState.IDLE;
	@observable error?: string;
	@observable entities: TModel[] = [];

	@computed public get size() {
		return size(this.entities);
	}

	constructor(
		protected readonly Model: {new (args: TModel): TModel},
		protected readonly api: <TReturn, TArgs = void>(
			args?: TArgs
		) => Promise<AxiosResponse<TReturn>>
	) {
		makeObservable(this);
	}

	@action async request() {
		if (this.apiState === RequestState.Requested) {
			return;
		}

		this.entities = [];
		this.apiState = RequestState.Requested;
		this.error = undefined;

		try {
			const response = await this.api<TModel[]>();

			runInAction(() => {
				this.entities = response.data.map((entity) => new this.Model(entity));
				this.apiState = RequestState.Received;
			});
		} catch (err) {
			this.apiState = RequestState.IDLE;
			this.error = (err as Error).message;
		}
	}
}
