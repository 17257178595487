import {createTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {DefaultTheme} from "styled-components";

const themeObject = {
	color: {
		primary: "#222222",
		secondary: "#ffffff",
		tertiary: "#",
		quaternary: "#",
		questionButton: "#ffffff",
		questionCounter: "#",
		listHeader: "#ffffff",
		listItem: "#000000",
		listItemPosition: "#A8999B",
		listButton: "#727272",
		menu: "#ffffff",
		activeMenu: "#ffffff",
		footer: "#222",
		footerLink: "#",
	},
	background: {
		primary: "#DA0021",
		secondary: "#ffffff",
		tertiary: "#",
		quaternary: "#ffffff",
		questionButton: "",
		questionButtonActive: "#",
		questionCounter: "#4",
		listHeader: "#EE3932",
		listItem: "#F0DBDD",
		listItemBorder: "#FEFEFE",
		listButton: "#F0DBDD",
		activeMenu: "#",
		menu: "#DA0021",
		form: "#ffffff",
		footer: "#ffffff",
	},
	button: {
		primary: {
			color: "#ffffff",
			background: "#D90F1B",
			borderColor: "#D90F1B",
			disabledColor: "#727272",
			disabledBackground: "#ffffff",
			disabledBorderColor: "#727272",
			hoverColor: "#EE3932",
			hoverBackground: "#ffffff",
			hoverBorderColor: "#EE3932",
			fontWeight: 700,
		},
		secondary: {
			color: "#EE3932",
			background: "#ffffff",
			borderColor: "#ffffff",
			disabledColor: "#727272",
			disabledBackground: "#ffffff",
			disabledBorderColor: "#ffffff",
			hoverColor: "#D90F1B",
			hoverBackground: "#ffffff",
			hoverBorderColor: "#ffffff",
			fontWeight: 700,
		},
	},
	breakpoints: {
		values: {
			small_mobile: 425,
			mobile: 768,
			tablet: 960,
			desktop: 1200,
			large_desktop: 1440,
		},
	},
};

export const theme = createTheme(themeObject) as unknown as DefaultTheme;
type ThemeInterface = typeof themeObject;

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */

declare module "@mui/material/styles" {
	interface Theme extends ThemeInterface {}

	interface BreakpointOverrides {
		xs: false; // removes the `xs` breakpoint
		sm: false;
		md: false;
		lg: false;
		xl: false;
		small_mobile: true; // adds the `mobile` breakpoint
		mobile: true; // adds the `mobile` breakpoint
		tablet: true;
		desktop: true;
		large_desktop: true;
	}
}

declare module "styled-components" {
	export interface DefaultTheme extends Theme {}
}
