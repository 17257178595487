import styled from "styled-components";
import {useIsMobile} from "modules/hooks";
import {ReactComponent as Ads} from "assets/images/ads.svg";
import {ReactComponent as MobileAds} from "assets/images/mobile-ads.svg";

const AdsWrapper = styled.div`
	overflow: hidden;
	width: 100%;
`;

const AdsContainer = styled.div`
	display: flex;
	background: #ffffff;
	width: 100%;
	min-height: 22px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: min-content;
	}
`;

const MobileContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 3px 9px;
`;

export const SmirnoffAds = () => {
	const {isMobile} = useIsMobile();

	return (
		<AdsWrapper>
			<AdsContainer>
				{isMobile ? (
					<MobileContainer>
						<MobileAds />
						<MobileAds />
					</MobileContainer>
				) : (
					Array.from({length: 20}).map((_, i) => <Ads key={i} />)
				)}
			</AdsContainer>
		</AdsWrapper>
	);
};
