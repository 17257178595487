import {isEmpty, get} from "lodash";
import {IApiResponse, IError} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	public static INVALID_CREDENTIALS = 403;
	public static USER_NOT_REGISTERED = 513;

	public static HAS_ERROR({errors}: IApiResponse) {
		return !isEmpty(errors) || Boolean(Array.isArray(errors) && errors.length);
	}

	public static CHECK(response: IApiResponse, code?: number) {
		if (ApiError.HAS_ERROR(response)) {
			const error = get(response, "errors[0]") as IError;

			if (error) {
				throw new ApiError(error.message, code || error.code);
			}
		}

		return response;
	}

	public code?: number;
	public text?: string;

	constructor(text: string, code?: number) {
		super(text);
		this.name = "API Error";
		this.text = text;
		this.code = code;
	}
}
