import {FC} from "react";
import styled from "styled-components";
import {IMAGES_URL} from "modules/constants";
import {ICocktail} from "modules/stores/Models";

const ITEM_HEIGHT = 44;

const Wrapper = styled.div`
	background: ${({theme}) => theme.background.listItem};
	border-bottom: 1px solid ${({theme}) => theme.background.listItemBorder};
	height: ${ITEM_HEIGHT}px;
	display: flex;
	align-items: center;
	padding: 6px 20px;
	font-size: 12px;
`;

const Number = styled.div`
	color: ${({theme}) => theme.color.listItemPosition};
	margin-right: 12px;
	min-width: 28px;
`;

const CocktailsLogoFigure = styled.div`
	margin-right: 12px;
	min-width: 32px;
	border-radius: 8px;
	overflow: hidden;
`;

export const CocktailsLogo = styled.img`
	width: 32px;
	height: 32px;
	object-fit: contain;
`;

const Name = styled.div`
	color: ${({theme}) => theme.color.listItem};
	line-height: 130%;
`;

interface ICocktailsItemProps extends ICocktail {
	index: number;
}

export const CocktailsListItem: FC<ICocktailsItemProps> = ({
	index,
	shortName,
	fullName,
	mainImageRankings,
	teamLogoRankings,
}) => {
	return (
		<Wrapper>
			<Number>#{index + 1}</Number>
			<CocktailsLogoFigure>
				<CocktailsLogo src={`${IMAGES_URL}${teamLogoRankings}`} loading="lazy" />
			</CocktailsLogoFigure>
			<Name>{fullName}</Name>
		</Wrapper>
	);
};
