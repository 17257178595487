import {FC, HTMLAttributes} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {PureButton} from "components/Buttons";

const MenuButton = styled(PureButton)<{color?: string}>`
	left: 20px;
	top: 45px;
	width: 30px;
	height: 30px;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 12;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 40px;
		height: 40px;
		left: 5.6vw;
	}

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 50%;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;
		background: ${({color}) => color || "#000000"};

		&:nth-child(even) {
			left: 50%;
		}

		&:nth-child(odd) {
			left: 0;
		}

		&:nth-child(1),
		&:nth-child(2) {
			top: 0;
		}

		&:nth-child(3),
		&:nth-child(4) {
			top: 8px;
		}

		&:nth-child(5),
		&:nth-child(6) {
			top: 16px;
		}
	}

	&.open span {
		background: ${({theme}) => theme.background.secondary};

		&:nth-child(1),
		&:nth-child(6) {
			transform: rotate(45deg);
		}

		&:nth-child(2),
		&:nth-child(5) {
			transform: rotate(-45deg);
		}

		&:nth-child(1) {
			left: 2px;
			top: 4px;
		}

		&:nth-child(2) {
			left: calc(50% - 2px);
			top: 4px;
		}

		&:nth-child(3) {
			left: -50%;
			opacity: 0;
		}

		&:nth-child(4) {
			left: 100%;
			opacity: 0;
		}

		&:nth-child(5) {
			left: 2px;
			top: 12px;
		}

		&:nth-child(6) {
			left: calc(50% - 2px);
			top: 12px;
		}
	}
`;

const ButtonInner = styled.div`
	width: 26px;
	height: 19px;
	position: relative;
	transition: 0.5s ease-in-out;
	transform: rotate(0deg);
`;

export const HamburgerButton: FC<HTMLAttributes<HTMLButtonElement> & {color?: string}> = observer(
	(props) => {
		return (
			<MenuButton {...props}>
				<ButtonInner>
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
				</ButtonInner>
			</MenuButton>
		);
	}
);
