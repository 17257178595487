import {FC, Fragment, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";
import {Flipped, Flipper} from "react-flip-toolkit";
import {useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {Exist, PureButton} from "components";
import {IconArrow} from "components/Arrow";
import {CocktailsListItem, CocktailsLogo} from "./CocktailsListItem";

const Wrapper = styled.div`
	position: fixed;
	right: 50px;
	top: 8vh;
	max-height: 84vh;
	width: 375px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	z-index: 10;
`;

const Header = styled.div`
	color: ${({theme}) => theme.color.listHeader};
	background: ${({theme}) => theme.background.listHeader};
	padding: 6px 20px;
	font-size: 12px;
	font-weight: 400;
	line-height: 160%;
`;

const FIGHTER_ITEM_HEIGHT = 45;

interface IListProps {
	isExpanded: boolean;
}

const List = styled.div<IListProps>`
	background: #fff;
	color: #000;
	max-height: ${(props) => (props.isExpanded ? "100vh" : `${FIGHTER_ITEM_HEIGHT * 3}px`)};
	transition: all 0.5s linear;
	overflow: auto;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
`;

const Button = styled(PureButton)`
	padding: 6px 20px;
	color: ${({theme}) => theme.color.listButton};
	background: ${({theme}) => theme.background.listButton};
	width: 100%;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 160%;
	text-align: center;
`;

const MobileButton = styled(PureButton)`
	background: #fff;
	position: fixed;
	right: 20px;
	top: 36px;
	width: 44px;
	height: 44px;
	border-radius: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-25%);
	border: 2px solid #cccccc;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 100%;
	}

	${IconArrow} {
		z-index: 2;
		position: absolute;
		bottom: -24px;
	}
`;

const CloseButton = styled(MobileButton)`
	background: #d90f1b;
	border: 2px solid var(--smirnoff-neutrals-white, #fff);

	&:before,
	&:after {
		background: #fff;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 18px;
		height: 3px;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`;

const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 15;
	display: flex;
	justify-content: flex-end;
`;

const MobileListContainer = styled.div`
	width: 100%;
	max-width: 375px;
	display: flex;
	flex-flow: column;
	margin-top: 86px;
`;

const QuizStart = styled.div`
	background: ${({theme}) => theme.background.listItem};
	color: ${({theme}) => theme.color.listItem};
	padding: 6px 20px;
	font-size: 12px;
	height: 44px;
	display: flex;
	align-items: center;
	line-height: 130%;
`;

const getIsVisible = ({
	isMobileView,
	isQuizStarted,
	isResultScreenVisible,
}: Record<string, boolean>) => {
	if (isResultScreenVisible) {
		return false;
	}

	return isMobileView && isQuizStarted;
};

const getIsVisibleDesktop = ({isMobileView, isResultScreenVisible}: Record<string, boolean>) => {
	if (isResultScreenVisible) {
		return false;
	}

	return !isMobileView;
};

export const CocktailsList: FC = observer(() => {
	const listRef = useRef<HTMLDivElement>(null);
	const {UIQuestionsProgress, UICocktailsList, I18n} = useStore();
	const isQuizStarted = UIQuestionsProgress.isAnyQuestionAnswered;
	const {isExpanded} = UICocktailsList;
	const [isMobileListVisible, setMobileListVisibleState] = useState(false);
	const isMobileView = useMediaQuery("(max-width: 768px)");
	const {resultScreenID, viewedQuestionID} = UIQuestionsProgress;
	const isResultScreenVisible = resultScreenID === viewedQuestionID;
	const isVisible = getIsVisible({
		isMobileView,
		isQuizStarted,
		isResultScreenVisible,
	});
	const isVisibleDesktop = getIsVisibleDesktop({
		isMobileView,
		isResultScreenVisible,
	});

	useEffect(() => {
		if (!isExpanded && listRef.current) {
			listRef.current.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [isExpanded, listRef]);

	const btnText = I18n.t(isExpanded ? "list.btn.less" : "list.btn.more");

	if (isVisible) {
		return (
			<Fragment>
				<MobileButton onClick={() => setMobileListVisibleState(true)}>
					<CocktailsLogo
						src={`${IMAGES_URL}${UICocktailsList.topCocktail.teamLogoRankings}`}
					/>
					<IconArrow />
				</MobileButton>
				<Exist when={isMobileListVisible}>
					<Overlay>
						<CloseButton onClick={() => setMobileListVisibleState(false)} />
						<MobileListContainer>
							<Header>{I18n.t("list.header.title")}</Header>
							<List isExpanded>
								{UICocktailsList.items.map((cocktail, index) => (
									<CocktailsListItem
										index={index}
										{...cocktail}
										key={cocktail.id}
									/>
								))}
							</List>
						</MobileListContainer>
					</Overlay>
				</Exist>
			</Fragment>
		);
	}

	if (isVisibleDesktop) {
		return (
			<Wrapper>
				<Header>{I18n.t("list.header.title")}</Header>
				<Exist when={isQuizStarted}>
					<List ref={listRef} isExpanded={isExpanded}>
						<Flipper flipKey={UICocktailsList.renderVersion}>
							{UICocktailsList.items.map((cocktail, index) => (
								<Flipped key={cocktail.id} flipId={cocktail.id}>
									<div>
										<CocktailsListItem
											index={index}
											{...cocktail}
											key={cocktail.id}
										/>
									</div>
								</Flipped>
							))}
						</Flipper>
					</List>
					<Button onClick={() => UICocktailsList.toggle()}>{btnText}</Button>
				</Exist>
				<Exist when={!isQuizStarted}>
					<QuizStart>{I18n.t("list.content.quiz_start")}</QuizStart>
				</Exist>
			</Wrapper>
		);
	}

	return null;
});
