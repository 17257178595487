import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {buttonStyles} from "components";
import {BaseLinkStyles, BaseExternalLink} from "./BaseLink";

export const PrimaryLink = styled(NavLink)`
	${BaseLinkStyles};
	${({theme}) => buttonStyles(theme.button.primary)}
`;

export const PrimaryExternalLink = styled(BaseExternalLink)`
	${BaseLinkStyles};
	${({theme}) => buttonStyles(theme.button.primary)}
`;
