import styled, {css} from "styled-components";

export const BaseLinkStyles = css`
	background: transparent;
	appearance: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	background-clip: padding-box;
	font-weight: 700;
	margin: 0;
	cursor: pointer;
	font-size: 16px;
	transition: all 0.2s linear;
	border: 1px solid;
	min-height: 44px;
	font-family: "Khand", sans-serif;
`;

export const BaseExternalLink = styled.a``;

BaseExternalLink.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};
