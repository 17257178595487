import {FC, PropsWithChildren, useEffect} from "react";
import styled from "styled-components";
import {useVisible} from "react-hooks-visible";
import {useStore} from "modules/hooks";

export const QuestionWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
`;

export const QuestionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
	position: relative;
	z-index: 2;
	font-weight: 700;
	padding: calc(4vh + 30px) 32px calc(4vh + 30px);

	@media (max-height: 900px) and (max-width: 500px) {
		padding: calc(4vh) 32px calc(4vh + 30px);
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: calc(8vh + 130px) 7% calc(4vh + 60px);
	}
`;

export const LogoContainer = styled.div<{
	smirnoffLogoText?: string;
	smirnoffLogoBackground?: string;
	sponsorText?: string;
	weDoText?: string;
	gameDaysText?: string;
}>`
	display: flex;
	justify-content: center;

	.smirnoff-logo-background {
		fill: ${({smirnoffLogoBackground}) => smirnoffLogoBackground || "#ffffff"};
	}

	.smirnoff-logo-text {
		fill: ${({smirnoffLogoText}) => smirnoffLogoText || "#D90F1B"};
	}

	.we-do-text {
		fill: ${({weDoText}) => weDoText || "#ffffff"};
	}

	.game-days-text {
		fill: ${({gameDaysText}) => gameDaysText || "#69F1FA"};
	}

	.sponsor-text {
		fill: ${({sponsorText}) => sponsorText || "#ffffff"};
	}
`;

export const QuestionCount = styled.div<{color: string}>`
	margin-top: 16px;
	line-height: 130.5%;
	color: ${({color}) => color || "#000000"};
	margin-bottom: 6px;

	${({theme}) => theme.breakpoints.up("small_mobile")} {
		margin-top: 24px;
	}
`;

export const QuestionTitle = styled.div<{color: string}>`
	margin-bottom: 10px;
	min-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	line-height: 130%;
	color: ${({color}) => color || "#000000"};

	${({theme}) => theme.breakpoints.up("small_mobile")} {
		margin-bottom: 16px;
	}
`;

interface ITrackQuestionViewingStateProps {
	id: number;
}

export const TrackQuestionViewingState: FC<PropsWithChildren<ITrackQuestionViewingStateProps>> = (
	props
) => {
	const {children, id} = props;
	const {UIQuestionsProgress} = useStore();
	const [targetRef, visible] = useVisible<HTMLDivElement, boolean>((vi: number) => vi > 0.5);

	useEffect(() => {
		if (visible) {
			UIQuestionsProgress.setViewedQuestion(id);
		}
	}, [visible, id, UIQuestionsProgress]);

	return (
		<div id={`question-${id}`} ref={targetRef}>
			{children}
		</div>
	);
};
