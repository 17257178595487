export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";
export const LINK = process.env.REACT_APP_LINK || "";

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";
export const IS_OFF_SEASON = JSON.parse(process.env.REACT_APP_IS_OFF_SEASON || "false") as boolean;
