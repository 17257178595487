export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum QuestionType {
	General = "general",
	Button = "button",
	Slider = "slider",
	Sponsored = "sponsored",
}

export enum Language {
	EN = "en",
}

export enum SaveButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum ButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum RoundStatusType {
	Active = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum TournamentStatusType {
	Playing = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum NavigationSide {
	Prev = "prev",
	Next = "next",
}

export enum ShareType {
	FB,
	TW,
	MOBILE,
}

export enum ModalType {
	Error,
	Info,
}
