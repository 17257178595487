import {FC} from "react";
import styled from "styled-components";
import {ReactComponent as Logo} from "assets/images/logo.svg";
import {ReactComponent as GSLogo} from "assets/images/GS-logo.svg";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${({theme}) => theme.color.footer};
	background-color: ${({theme}) => theme.background.footer};
	padding: 35px 0 27px;
`;

const GSLogoWrapper = styled.a`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Divider = styled.hr`
	width: 100%;
	border-top: 1px solid #000000;
`;

const List = styled.ul`
	padding: 0 24px;
	gap: 0.75rem;
	grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	display: grid;
	width: 100%;
	margin: 12px auto;

	${({theme}) => theme.breakpoints.up("mobile")} {
		gap: 1rem;
		padding: 0 46px;
		width: 70%;
		margin: 28px auto;
	}

	&.justify {
		width: 100%;
		display: flex;
		flex-direction: column;

		${({theme}) => theme.breakpoints.up("mobile")} {
			gap: 60px;
			flex-direction: row;
		}

		li {
			display: flex;
			width: 100%;
			white-space: normal;

			&:last-child {
				${({theme}) => theme.breakpoints.up("mobile")} {
					justify-content: flex-end;
				}

				${({theme}) => theme.breakpoints.up("desktop")} {
					justify-content: flex-start;
				}
			}
		}
	}

	a,
	li {
		white-space: nowrap;
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 12px;
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}
`;

export const Footer: FC = () => {
	return (
		<Wrapper>
			<Logo />

			<List>
				<li>
					<a
						href="https://footer.diageohorizon.com/dfs/assets/www.smirnoff.com/TnC_en.html?locale=en-row"
						rel="noreferrer noopener"
						target="_blank"
						title="Conditions of Use">
						Conditions of Use
					</a>
				</li>

				<li>
					<a
						href="https://www.diageoprivacycentre.com/en-row?pp=https://footer.diageohorizon.com/dfs/assets/www.smirnoff.com/PrivacyPolicy_en.html?locale=en-row"
						rel="noreferrer noopener"
						target="_blank"
						title="Diageo Privacy Centre">
						Diageo Privacy Centre
					</a>
				</li>
				<li>
					<a
						href="https://footer.diageohorizon.com/dfs/assets/www.smirnoff.com/UGC_en.html?locale=en-row"
						rel="noreferrer noopener"
						target="_blank"
						title="UGC Policy">
						UGC Policy
					</a>
				</li>
				<li>
					<a
						href="https://footer.diageohorizon.com/dfs/assets/www.smirnoff.com/www.smirnoff.com_en-row_final_accessibility.html"
						rel="noreferrer noopener"
						target="_blank"
						title="Accessibility">
						Accessibility
					</a>
				</li>
				{/*<li><a className="optanon-toggle-display cookie-settings-button"*/}
				{/*                                    title="Privacy Settings" href="javascript:void(0);"*/}
				{/*                                   >Privacy Settings</a></li>*/}
				<li>
					<a
						href="https://footer.diageohorizon.com/dfs/assets/www.smirnoff.com/PrivacyPolicy_en.html?locale=en-row"
						rel="noreferrer noopener"
						target="_blank"
						title="Privacy and Cookie Notice">
						Privacy and Cookie Notice
					</a>
				</li>
				<li>
					<a
						href="https://www.responsibility.org/"
						rel="noreferrer noopener"
						target="_blank"
						title="Drink Responsibly">
						Drink Responsibly
					</a>
				</li>
				<li>
					<a
						href="https://www.drinkiq.com"
						target="_blank"
						rel="noreferrer noopener"
						title="DRINKiQ">
						DRINKiQ
					</a>
				</li>
				<li>
					<a
						href="https://www.thebar.com"
						target="_blank"
						rel="noreferrer noopener"
						title="theBar.com">
						theBar.com
					</a>
				</li>
			</List>

			<Divider />

			<List className={"justify"}>
				<li>
					Please do not share with anyone under the legal purchase age for alcohol. Drink
					Responsibly.
				</li>
				<li>© Diageo 2023</li>
			</List>

			<GSLogoWrapper href="https://www.geniussports.com/genius-marketing-suite/genius-games/">
				<GSLogo />
			</GSLogoWrapper>
		</Wrapper>
	);
};
