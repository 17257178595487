import {Api} from "modules/utils/Api";
import {Fingerprint, I18n, Modal, Question, Cocktail} from "modules/stores/Models";
import {JSONCollection} from "modules/stores/Collections";
import {UIQuestionsProgress, UICocktailsList} from "modules/stores/UIStores";

export class RootStore {
	fingerprint: Fingerprint;
	cocktails: JSONCollection<Cocktail>;
	questions: JSONCollection<Question>;
	UICocktailsList: UICocktailsList<RootStore>;
	UIQuestionsProgress: UIQuestionsProgress<RootStore>;
	I18n: I18n;
	modal: Modal;

	constructor() {
		this.fingerprint = new Fingerprint();
		this.cocktails = new JSONCollection(Cocktail, Api.JSON.cocktails);
		console.log(this.cocktails);
		this.questions = new JSONCollection(Question, Api.JSON.questions);
		this.UICocktailsList = new UICocktailsList(this);
		this.UIQuestionsProgress = new UIQuestionsProgress(this);
		this.I18n = new I18n();
		this.modal = new Modal();
	}
}

export default RootStore;
