import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {BASE_URL, IS_OFF_SEASON} from "modules/constants";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {theme} from "assets/css/theme";
import {RootStore, StoreProvider} from "modules/stores";
import Home from "pages/Home";
import NotFound from "pages/404";
import {ErrorModal, Layout} from "components";
import OffSeason from "pages/OffSeason";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://f0db0894e2acdcb574c5c1d8899ec786@o151969.ingest.sentry.io/4505624267915264",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: ["https://gamedaysquiz.smirnoff.com", ".f2p.media.geniussports.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
	ignoreErrors: [
		"Non-Error promise rejection captured",
		"Abort due to cancellation of share.",
		"AbortError: Share canceled",
	],
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
const rootStore = new RootStore();

root.render(
	<StrictMode>
		<StoreProvider store={rootStore}>
			<ThemeProvider theme={theme}>
				<Router basename={BASE_URL}>
					<ErrorModal />

					{IS_OFF_SEASON ? (
						<OffSeason />
					) : (
						<Routes>
							<Route element={<Layout />}>
								<Route path="/" element={<Home />} />
								<Route path="*" element={<NotFound />} />
							</Route>
						</Routes>
					)}
				</Router>
			</ThemeProvider>
		</StoreProvider>
	</StrictMode>
);
