import {QuestionType} from "modules/enums";

export interface IColors {
	background: string;
	burger: string;
	smirnoffLogoText: string;
	smirnoffLogoBackground: string;
	sponsorText: string;
	weDoText: string;
	gameDaysText: string;
	questionNo: string;
	question: string;
	optionBackground: string;
	optionText: string;
}

export class Question {
	public id: number = 0;
	public colors: IColors = {
		background: "#ffffff",
		burger: "#000000",
		gameDaysText: "#000000",
		smirnoffLogoBackground: "#000000",
		smirnoffLogoText: "#000000",
		sponsorText: "#000000",
		weDoText: "#000000",
		questionNo: "#000000",
		question: "#000000",
		optionBackground: "#ffffff",
		optionText: "#000000",
	};
	public position: number = 0;
	public title: string = "";
	public shortTitle: string = "";
	public type: QuestionType = QuestionType.General;
	public options: Array<{
		id: number;
		title: string;
		image: string;
		mobileImage: string;
		points: Record<string, number>;
	}> = [];

	constructor(args: Question) {
		Object.assign(this, args);
	}
}

export interface IQuestion extends Question {
	hasAnswer: boolean;
	isActive: boolean;
	questionNumber: number;
}
