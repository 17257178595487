import {FC} from "react";
import styled from "styled-components";
import {Checkbox as DefaultCheckbox, FormControlLabel} from "@mui/material";
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";

export const CheckboxLabel = styled.span`
	font-size: 14px;
	line-height: 18px;
	color: #000000;
	font-family: "Normalidad", sans-serif;
`;

const ControlLabel = styled(FormControlLabel)`
	.MuiSvgIcon-root {
		path {
			color: ${({theme}) => theme.color.quaternary};
		}
	}

	.Mui-checked .MuiSvgIcon-root path {
		color: ${({theme}) => theme.color.quaternary};
	}
`;

interface ICheckbox extends CheckboxProps {
	name: string;
	html: string | TrustedHTML;
}

export const Checkbox: FC<ICheckbox> = ({name, html, ...rest}) => (
	<ControlLabel
		control={<DefaultCheckbox name={name} {...rest} />}
		label={<CheckboxLabel dangerouslySetInnerHTML={{__html: html}} />}
	/>
);
